import { gql } from '~/__gql-generated__';
import { PostType } from '~/__gql-generated__/graphql';
import {
  cache,
  createAsync,
  useParams,
  type RouteDefinition,
} from '@solidjs/router';
import { Match, Switch } from 'solid-js';

import Post from '~/components/Post';
import PostList from '~/components/PostList';
import { client } from '~/utils/graphql';
import NotFound from './[...404]';

enum SlugType {
  CATEGORY,
  POST,
  USER,
  NONE,
}

const SLUG_TYPE = gql(`
  query SlugType($slug: String!) {
    categories(where: { slug: $slug }, options: { limit: 1 }) {
      title
      body
    }
    posts(
      where: { slug: $slug, status: PUBLISHED, type: PAGE }
      options: { limit: 1 }
    ) {
      id
    }
    users(where: { profile: { slug: $slug } }, options: { limit: 1 }) {
      displayName
      profile {
        slug
      }
    }
  }
`);

const getSlugType = cache(async (slug: string): Promise<[SlugType, any]> => {
  'use server';

  const { data } = await client.query({
    query: SLUG_TYPE,
    variables: { slug },
  });

  if (data.categories.length > 0) {
    return [SlugType.CATEGORY, data.categories[0]];
  }

  if (data.posts.length > 0) {
    return [SlugType.POST, undefined];
  }

  if (data.users.length > 0) {
    return [SlugType.USER, data.users[0]];
  }

  return [SlugType.NONE, ''];
}, 'slugType');

export const route = {
  preload: ({ location }) => {
    const [, slug] = location.pathname.split('/');
    return getSlugType(slug);
  },
} satisfies RouteDefinition;

export default function Slug() {
  const parameters = useParams();
  const type = createAsync(() => getSlugType(parameters.slug));

  return (
    <Switch fallback={<NotFound />}>
      <Match when={type()?.[0] === SlugType.CATEGORY}>
        <PostList filter={{ category: { slug: parameters.slug } }} />
      </Match>
      <Match when={type()?.[0] === SlugType.POST}>
        <Post type={PostType.Page} />
      </Match>
    </Switch>
  );
}
